import React from "react"
import Grid from "../../modules/grid"
import Row from "../../modules/grid/row"
import Column from "../../modules/grid/column"
import FeaturedLocationTile from "../../modules/tile/featured/location"
import LocationTile from "../../modules/tile/location"
import Spacer from "../../layout/spacer"
import maps from "./maps.json"
import { minDesktopSize } from "../../../variables"
import { openExternalLink } from "../../../helpers"

type Office = {
  details: string
  external_link: string
  hidden: boolean
  id: number
  image_large: string
  image_small: string
  name: string
  position: number
}

interface LocationTypes {
  offices: Office[]
}

const Locations: React.FC<LocationTypes> = ({ offices }) => {
  const mainOffice = offices[0]
  return (
    <>
      <Grid modifierClass="locations content-page">
        <Row columnCount={1}>
          <Column>
            <FeaturedLocationTile
              position={mainOffice.position}
              image_large={mainOffice.image_large}
              name={mainOffice.name}
              details={mainOffice.details}
              toggleCtaLabel="See on Google Maps"
              handleToggleCtaClick={() =>
                openExternalLink(mainOffice.external_link)
              }
            />
            <Spacer deviceSize="s" />
          </Column>
        </Row>
        <Row columnCount={3}>
          {offices.splice(1, offices.length).map((office) => {
            return (
              <Column key={office.id}>
                <LocationTile
                  external_link={office.external_link}
                  name={office.name}
                  details={office.details}
                  image_large={office.image_large}
                  position={office.position}
                />

                <Spacer deviceSize="s" />
              </Column>
            )
          })}
        </Row>
      </Grid>
    </>
  )
}

export default Locations
