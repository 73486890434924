import React, { useState } from "react"
import Link from "./link"
import Expander from "./expander"
import Spacer from "../../layout/spacer"
import classnames from "classnames"
import useDesktopDetector from "../../../hooks/useDesktopDetector"
import LoadingIndicator from "../../elements/loading_indicator"

type Themes = "white" | "grey"

interface ListTypes {
  list: ItemTypes[]
  isListLoading: boolean
  heading?: string
  theme?: Themes | string
  parentPath?: string
}

export interface ItemTypes {
  name: string
  slug: string
}

const ListModule: React.FC<ListTypes> = ({
  isListLoading = true,
  list,
  heading = "",
  theme = "white",
  parentPath,
}) => {
  const listCount = list.length
  const maxLinks = 18 // 15 is 5 rows...
  const [isToggled, setIsToggled] = useState(false)
  const handleExpanderToggle = () => {
    setIsToggled(!isToggled)
  }

  const gridClassnames = classnames({
    "row-limit": listCount > maxLinks,
    "row-expanded": isToggled,
  })

  function maxHeight() {
    const rowHeight = 59
    const count = listCount

    const rows = count / 3
    const maxHeight = rows * rowHeight

    return `${maxHeight}px`
  }

  const isDesktop = useDesktopDetector()

  function defaultHeight() {
    const rowHeight = 62
    if (isDesktop) {
      const height = rowHeight * 5
      return `${height}px`
    } else {
      const height = rowHeight * 14
      return `${height}px`
    }
  }

  const themeClassnames = classnames({
    white: theme === "white",
    grey: theme === "grey",
  })

  const handleSlug = (slug) => {
    if (parentPath === null) return `/${slug}`
    else return `${parentPath}/${slug}`
  }

  return (
    <section className={`list-module ${themeClassnames}`}>
      <div className="list-module__content">
        {heading !== "" && (
          <>
            <h1 className="typography__display--05">{heading}</h1>
            <Spacer deviceSize="s" />
          </>
        )}

        <ul
          className={`list-module__grid ${gridClassnames}`}
          style={{
            maxHeight: `${isToggled ? maxHeight() : defaultHeight()}`,
          }}
        >
          {isListLoading ? (
            <div className="container">
              <LoadingIndicator />
            </div>
          ) : (
            list.map(({ name, slug }, i) => {
              return (
                <li key={i} className="list-module__column">
                  <Link slug={handleSlug(slug)} name={name} />
                  <Spacer deviceSize="xs" />
                </li>
              )
            })
          )}
        </ul>

        {listCount > maxLinks && (
          <>
            <Spacer deviceSize="m" />
            <Expander
              name="Show me more capabilities"
              onClick={handleExpanderToggle}
              toggled={isToggled}
            />

            <Spacer deviceSize="m" />
          </>
        )}
      </div>
    </section>
  )
}

export default ListModule
