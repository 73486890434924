import React from "react"
import ContentPage from "../../layout/content_page"
import Wysiwyg from "../../ui/wysiwyg"
import Spacer from "../../layout/spacer"
import Grid from "../../modules/grid"
import Row from "../../modules/grid/row"
import Column from "../../modules/grid/column"
import Multimedia from "../../modules/multimedia"
import RelatedNews from "../../modules/related_news"
import SideNav from "../../modules/side_nav"
import useDesktopDetector from "../../../hooks/useDesktopDetector"
import CtaBanner from "../../modules/cta_banner"
import ContentWithStickyAside from "../../modules/content_with_sticky_aside"
import Breadcrumbs from "../../ui/breadcrumbs"

import FeaturedProjectTile from "../../modules/tile/featured/project"
import { PageModuleTypes } from "./types"

function wysiwygContent(
  isDesktop: boolean,
  sideNav: () => void,
  wysiwyg: string
): React.ReactElement {
  return (
    <>
      <ContentPage modifierClass="generic-page">
        {!isDesktop && sideNav()}
        <section className="content-page__inner content-page__inner--lift-up">
          <section id="overview" className="content-page__with-sidebar">
            {wysiwyg.length >= 1 && <Wysiwyg content={wysiwyg} />}
          </section>
        </section>
      </ContentPage>
    </>
  )
}

const GenericPage: React.FC<PageModuleTypes> = ({
  parentPage,
  pageName,
  showSideNavCta,
  sideNavCtaText,
  sideNavCtaPath,
  ctaBanner,
  wysiwyg,
  downloads,
  videos,
  featuredNews,
  featureProject,
  headingForVideos,
  leadGen,
  hasLeadGenForm,
  leadGenFormUrl,
  relatedNewsHeading,
  hideRelatedNews,
}) => {
  const isDesktop = useDesktopDetector()
  const sideNav = () => {
    return (
      <SideNav
        cta={{
          isVisible: showSideNavCta,
          label: sideNavCtaText,
          link: sideNavCtaPath,
        }}
        isArticle={false}
        isCampaign={false}
        name={leadGen.name}
        linkedin={leadGen.linkedin}
        title={leadGen.title}
        email={leadGen.email}
        image1x={leadGen.image1x}
        image2x={leadGen.image2x}
        phone={leadGen.phone}
        contactLabel={leadGen.contactLabel}
        hidePhone={leadGen.hidePhone}
        hideEmail={leadGen.hideEmail}
        team={null}
        hasLeadGenForm={hasLeadGenForm}
        formHtml={leadGenFormUrl}
        relatedCapabilities={[]}
        relatedIndustries={[]}
        relatedServices={[]}
        relatedLocations={[]}
      />
    )
  }

  return (
    <>
      {parentPage && parentPage.name.toLowerCase() === "careers" && (
        <Breadcrumbs
          isParentPage={false}
          parentPage={{ slug: "/careers", name: "Careers" }}
          currentPage={pageName}
        />
      )}
      <ContentWithStickyAside
        hasStickySubNav={false}
        content={wysiwygContent(isDesktop, sideNav, wysiwyg)}
        aside={sideNav()}
      />

      {(downloads.length >= 1 || videos.length >= 1) && (
        <Multimedia
          videos={videos}
          downloads={downloads}
          heading={headingForVideos}
        />
      )}
      {featureProject && (
        <ContentPage>
          <Grid>
            <Row columnCount={1}>
              <Column>
                <FeaturedProjectTile
                  id={featureProject.id}
                  client_name={featureProject.client_name}
                  listing_image={`${featureProject.listing_images.large}`}
                  name={featureProject.name}
                  teaser={featureProject.teaser}
                  slug={featureProject.slug}
                  leftAlignedFeature={true}
                  currentPath="/projects"
                />
              </Column>
            </Row>
          </Grid>
          {featuredNews.length >= 1 && <Spacer desktopSize="s" />}
        </ContentPage>
      )}

      {featuredNews.length >= 1 && !hideRelatedNews && (
        <>
          <RelatedNews
            articles={featuredNews}
            articlesHeading={relatedNewsHeading ? relatedNewsHeading : ""}
            inCampaign={false}
          />
        </>
      )}

      {ctaBanner && (
        <CtaBanner
          isContentBanner={true}
          title={ctaBanner.title}
          description={ctaBanner.description}
          buttonTitle={ctaBanner.button_title}
          ctaLink={ctaBanner.button_url}
          footer={ctaBanner}
        />
      )}
    </>
  )
}

export default GenericPage
