import React from "react"
import { useInView } from "react-intersection-observer"
import ContentPage from "../../../layout/content_page"
import useDesktopDetector from "../../../../hooks/useDesktopDetector"
import useTabletDetector from "../../../../hooks/useTabletDetector"
import Picture from "../../../elements/picture"
import Wysiwyg from "../../../ui/wysiwyg"
import PrimaryCta from "../../../cta/primary_cta"
import useComponentInView from "../../../../hooks/useComponentInView"

import {
  motion,
  useViewportScroll,
  useTransform
} from "framer-motion"

import IconSrollDownPrompt from "../../../../images/svgs/icon_scroll_down_prompt"
import { colors } from "../../../../variables"
import { handleScrollTo } from "../../../../helpers"

import { SliderBannerTypes } from "./types"



const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.75,
    },
  },
}


const animateIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}


const ShowcaseSliderBanner: React.FC<SliderBannerTypes> = ({
  banners,
  loopingVidBanners,
  logo,
  introText,
  heading,
  ctaLink,
  ctaLabel,
}) => {

  const [ref, inView] = useInView({
    threshold: 0.2,
  })

  const isDesktop = useDesktopDetector()
  const isTablet = useTabletDetector()
  const componentSeen = useComponentInView(inView)

  const { scrollYProgress } = useViewportScroll();
  const xPosAnim = useTransform(scrollYProgress, [0, 0.5, 1], [0, -15000, -7000])
  const smallxPosAnim = useTransform(scrollYProgress, [0, 0.5, 1], [0, -1000, -2500])


  return (

    <div className="showcase-banner__wrapper showcase-wrapper__showcase-banner">
      <div id="print-only-logo">
        <img src="/images/ventia_logo_white.svg" />
      </div>
      <div
        id="print-only-banner"
        className={`print-only-banner`}
      >
        {banners[0] && (
          <picture className="print-only-banner__picture">
            <img src={banners[0].image} />
          </picture>
        )}
        <span className="print-only-banner__picture-bg" />
      </div>
      <>
        <div
          role="banner"
          className={`slider-banner slider-banner__showcase-page showcase__full-gradient-after  `}
        >
          <div className="slider-banner__words-wrapper ">
            {isDesktop ? (
              <div className="slider-banner__heading">
                <h1 className={`sr-only`}>
                  {heading}
                </h1>
                <motion.div
                  style={{
                    x: xPosAnim
                  }}
                  aria-hidden="true"
                  variants={container}
                  className="typography__showcase-banner--title typography__weight--300 typography__color--white typography__text-shadow"
                >
                  {[...Array(30)].map((index) => (
                    <motion.span
                      key={index}
                      initial="hidden"
                      animate={componentSeen ? "visible" : "visible"}
                      variants={animateIn}
                      transition={{
                        default: {
                          damping: 20,
                          delay: 1.8,
                          duration: 0.6,
                          type: "spring",
                          ease: "easeOut",
                        },
                      }}
                    >
                      {heading}&nbsp;/&nbsp;
                    </motion.span>
                  ))}
                </motion.div>
              </div>
            ) : (
              <div className="slider-banner__heading">
                <h1 className={`sr-only`}>
                  {heading}
                </h1>
                <motion.div
                  style={{
                    x: smallxPosAnim
                  }}
                  aria-hidden="true"
                  variants={container}
                  className="typography__showcase-banner--title typography__weight--300 typography__color--white typography__text-shadow"
                >
                  {[...Array(30)].map((index) => (
                    <motion.span
                      key={index}
                      initial="hidden"
                      animate={componentSeen ? "visible" : "visible"}
                      variants={animateIn}
                      transition={{
                        default: {
                          damping: 20,
                          delay: 1.8,
                          duration: 0.6,
                          type: "spring",
                          ease: "easeOut",
                        },
                      }}
                    >
                      {heading}&nbsp;/&nbsp;
                    </motion.span>
                  ))}
                </motion.div>
              </div>
            )}


            <motion.div
              className={`showcase-banner-scroll`}
              transition={{
                yoyo: Infinity,
                ease: "easeOut"
              }}
              animate={{
                y: ["1rem", "0rem"]
              }}
            >
              <button
                className="showcase-banner-scroll__icon"
                onClick={() => handleScrollTo("target", -100)}>
                <span>
                  <IconSrollDownPrompt color={colors.white} />
                </span>
              </button>
            </motion.div>
          </div>
          <div className="page-banner">
            <div className="page-banner__effect">
              <div className="page-banner__wrapper swiper-wrapper">
                {!!banners.length &&
                  banners.map(({ image }, i) => {
                    return (

                      <div key={i} className="page-banner__slide  swiper-slide ">
                        <div
                          key={i}
                          className="page-banner__slide-background"
                          style={{ backgroundImage: `url(${image})` }}
                        />
                      </div>

                    )
                  })}
                {!!loopingVidBanners &&
                  loopingVidBanners.map(({ files }, i) => {
                    return (

                      <div key={i} className="page-banner__slide  swiper-slide">
                        <video
                          playsInline={true}
                          className="homepage-video home-bg__video active"
                          width="100%"
                          poster={files.mobile_image_mobile}
                          muted={true}
                          loop={true}
                          autoPlay
                        >
                          <source
                            id="webmSource"
                            src={files.webm_version}
                            type="video/webm"
                          />
                          <source
                            id="mp4Source"
                            src={files.mp4_version}
                            type="video/mp4"
                          />
                        </video>
                      </div>

                    )
                  })}
              </div>
            </div>
          </div>
        </div>


        <div id="target" className="page-content-wrap showcase-intro-wrap showcase-bg--white">
          <ContentPage>
            <section className="showcase__intro">
              <div className="showcase__intro--logos-wrap">
                {logo && (
                  <div className="showcase__intro--logos">
                    <Picture
                      cssClassName="showcase__intro--logos__image-wrapper"
                      image1x="/images/ventia_logo_black.svg"
                      image2x="/images/ventia_logo_black.svg"
                    />
                    <div className="showcase__intro--logos__divide"></div>
                    <Picture
                      cssClassName="showcase__intro--logos__image-wrapper"
                      image1x={logo}
                      image2x={logo}
                    />
                  </div>
                )}
              </div>


              <div className="showcase__intro--copy">
                <Wysiwyg content={introText} />

                {ctaLink && (
                  <>
                    <div className="showcase__intro--btn-wrapper">
                      <PrimaryCta link={ctaLink} label={ctaLabel} />
                    </div>
                  </>
                )}

              </div>
            </section>
          </ContentPage>
        </div>


      </>
    </div>

  )
}

export default ShowcaseSliderBanner
