import React from "react"
import IconDownload from "../../images/svgs/icon_download"
import { colors } from "../../variables"

interface DownloadTypes {
  link: string
}

const Download: React.FC<DownloadTypes> = ({ link }) => {
  return (
    <a className="tile__cta download" href={link}>
      <IconDownload color={colors.green} />
    </a>
  )
}

export default Download
