import React from "react"

interface LoadingSpinnerTypes {
  isCta: boolean
}

const LoadingSpinner: React.FC<LoadingSpinnerTypes> = ({ isCta = false }) => {
  return isCta ? (
    <div
      className="form-submit__loading-indicator lds-ring"
      style={{ display: "none" }}
    >
      <div className="white"></div>
      <div className="white"></div>
      <div className="white"></div>
      <div className="white"></div>
    </div>
  ) : (
    <div className="lds-ring">
      <div className="dark-blue"></div>
      <div className="dark-blue"></div>
      <div className="dark-blue"></div>
      <div className="dark-blue"></div>
    </div>
  )
}

export default LoadingSpinner
